@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@300;700&display=swap');

// Customize Bootstrap variables
@import 'variables';

// Import bootstrap distribution
@import '~bootstrap/scss/bootstrap';

// Customize bootstrap classes
.btn {
  text-transform: uppercase;
}
