// Install branded fonts
$font-family-sans-serif: 'Roboto', sans-serif;
$headings-font-family: 'Montserrat', sans-serif;
$btn-font-family: $headings-font-family;
// $enable-caret: false;

// remove icons from form validation states
$form-feedback-icon-valid: "";
$form-feedback-icon-invalid: "";

// Import actual bootstrap vars. Vars defined above will override these
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
